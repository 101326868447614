<template>
  <el-dialog
    :title="text"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div class="tips">
      <span class="el-icon-warning icon"></span>
      <span> 是否确认{{ text }}申请</span>
    </div>

    <p>申请退款金额： ￥{{ applyRefundAmount }}</p>
    <el-form :inline="true">
      <el-form-item :required="true" label="实际退款金额">
        <el-input v-model="actualRefundAmount" placeholder="请输入退款金额">
          <template slot="prepend">￥</template>
        </el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { agreeReturn, agreeReturnGoods } from "@/assets/api/order";
export default {
  props: {
    details: {
      type: Object,
      default: {}, // 详情
    },
  },

  watch: {
    dialogVisible(newValue) {
      // 弹窗显示
      if (newValue) {
        this.text =
          this.details.type == "returnGoods" ? "同意退货" : "同意退款";
        this.applyRefundAmount = this.details.applyRefundAmount;
        this.actualRefundAmount = this.details.actualRefundAmount;
        this.switchApi =
          this.details.type == "returnGoods" ? agreeReturnGoods : agreeReturn;
      }
    },
  },

  data() {
    return {
      dialogVisible: false, // 弹窗
      applyRefundAmount: 0, // 申请退款金额
      text: "同意退货", // 弹窗标题
      actualRefundAmount: 0, // 实际退款金额
    };
  },

  methods: {
    submit() {
      // 提交
      if (this.actualRefundAmount > this.details.applyRefundAmount) {
        this.$message.error("退款金额不能大于申请退款金额");
      } else {
        const parameter = {
          actualRefundAmount: this.actualRefundAmount,
          id: this.details.id,
          status: this.details.status,
        };
        this.switchApi({
          ...parameter,
        }).then((res) => {
          const { code, message, data } = res;
          this.dialogVisible = false;
          this.$emit("change");
          if (code == 200) {
            this.$message.success(message);
          } else {
            this.$message.error(message);
          }
        });
      }
    },

    handleClose(done) {
      // 关闭弹窗
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style scoped>
.icon {
  color: #e6a23c;
}
.tips {
  padding-bottom: 20px;
}
</style>
